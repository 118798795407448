<template lang="pug">
.list-download

  manage-list
</template>

<script>
import { ManageList } from '@/components/list'

export default {
  name: 'ListDownload',
  components: {
    ManageList
  }
}
</script>

<style lang="stylus" scoped></style>
