<template lang="pug">
.list
  .text-h5.font-weight-bold.primary--text Ringelister
  .text-subtitle-2.mt-2.mb-6 Ringelister du oppretter her er private og kun tilgjengelige for din bruker.

  v-tabs.mb-8(
    background-color="transparent"
    color="secondary"
  )
    v-tab(
      to="/list"
      exact
    ) Opprett liste
    v-tab(
      to="/list/download"
    ) Last ned lister
    v-tab(
      to="/list/rotation"
    ) Hvordan virker det?

  router-view
</template>

<script>
export default {
  name: 'List'
}
</script>

<style lang="stylus" scoped>
.list
  .v-tabs
    position relative

    >>> .v-slide-group__content
      &::after
        content ''
        height 2px
        background rgba(0, 0, 0, .2)
        display block
        position absolute
        left 0
        right 0
        bottom 0
</style>
