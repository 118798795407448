<template lang="pug">
v-card.card-simple.rounded-xl
  .text-h5.font-weight-bold.primary--text.pa-8.pb-0 Legg til filtre
  v-card-text.pa-8
    form-filters(v-model="filters")

    //- Limit
    .mb-4
      v-slider(
        v-model="limit"
        :min="10"
        :max="1000"
        height="48"
        color="secondary"
        track-color="#0000001f"
        hide-details
      )
        template(#prepend)
          .d-flex(style="white-space:nowrap;height:40px;align-items:center;")
            v-icon(color="primary" small left) mdi-format-list-numbered
            .text-subtitle-1.font-weight-normal.primary--text Max antall resultat
        template(#append)
          v-text-field(
            v-model="limit"
            type="number"
            :min="10"
            :max="1000"
            style="width:110px"
            hide-details
            rounded filled dense
          )

    v-btn.mt-8(
      :disabled="loading"
      :loading="loading"
      @click="submit"
      color="secondary"
      x-large block depressed rounded
    ) Opprett
</template>

<script>
import { isEqual } from 'lodash'
import { mapActions } from 'vuex'
import { FormFilters } from '@/components/form'

export default {
  name: 'CreateList',
  components: { FormFilters },
  data: () => ({
    loading: false,

    filters: {},
    limit: 10
  }),
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('List', ['createList']),
    async submit() {
      if (isEqual(this.filters, {})) return

      this.loading = true
      try {
        const { list_id } = await this.createList({
          limit: this.limit,
          filters: this.filters
        })
        this.$router.push(`/list/download#${list_id}`)
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
