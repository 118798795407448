<template lang="pug">
.list-create
  create-list
</template>

<script>
import { CreateList } from '@/components/list'

export default {
  name: 'ListCreate',
  components: {
    CreateList
  }
}
</script>

<style lang="stylus" scoped></style>
