<template lang="pug">
.manage-list

  v-skeleton-loader(
    v-if="loading"
    type="paragraph"
  )
  template(v-else)
    template(v-if="lists.length <= 0")
      .d-flex.justify-center
        v-img.mt-12(
          :src="require('../../assets/img/undraw/undraw_selection_re_ycpo.svg')"
          max-width="300"
        )

    template(v-else)
      v-expansion-panels.expansion-panels-simple(
        v-model="panel"
      )
        v-expansion-panel.rounded-xl.mb-4(
          v-for="(list) in lists"
          :key="`list-${list.list_id}`"
        )
          v-expansion-panel-header
            v-list(color="transparent")
              v-list-item(color="secondary")
                v-list-item-avatar.mr-3.my-0
                  v-progress-circular(
                    v-if="isGenerating(list)"
                    size="36"
                    color="#80dbc1"
                    indeterminate
                  )
                  v-icon(
                    v-else
                    color="#80dbc1"
                    large
                  ) mdi-phone-log
                v-list-item-content.py-0
                  v-list-item-title
                    .d-inline.mr-2(v-html="formatTitle(list)")
                    v-chip.px-2(
                      v-if="isPaid(list)"
                      color="#5a3de6"
                      dark x-small
                    ) Betalt
                  v-list-item-subtitle
                    .d-inline.mr-2 {{ formatSubtitle(list) }}
          v-expansion-panel-content
            template(v-if="!isPaid(list)")
              v-simple-table.mb-6(
                v-if="formatHead(list).length > 0"
                dense
              )
                template(#default)
                  thead
                    tr
                      th Nummer
                      th Navn
                      th Kjønn
                      th Postnummer
                      th.text-right By
                  tbody
                    tr(
                      v-for="(record) in formatHead(list)"
                      :key="`record-${record.nrop.phone}`"
                    )
                      td {{ record.nrop.phone.slice(0, -4) }}••••
                      td {{ record.nrop.firstName }} {{ record.nrop.middleName }} {{ record.nrop.lastName }}
                      td {{ record.freg.gender }}
                      td {{ record.nrop.postalCode }}
                      td.text-right {{ record.nrop.city }}

              template(v-if="formatPrice(list).count > 0")
                .text-h6.font-weight-normal.primary--text
                  v-icon.mb-1(color="primary" left) mdi-storefront
                  | Kjøp listen
                v-divider.mt-2

                v-simple-table(dense)
                  template(#default)
                    tbody
                      tr
                        td Antall numre
                        td.text-right {{ formatPrice(list).count }} st
                      tr
                        td Sats
                        td.text-right 10 NOK
                      tr
                        td Pris
                        td.text-right {{ formatPrice(list).price }} NOK
                      tr
                        td MVA (25%)
                        td.text-right {{ formatPrice(list).price_tax }} NOK
                      tr
                        td Totalt
                        td.text-right.font-weight-bold
                          | {{ formatPrice(list).price_total }} NOK

            .d-flex.mt-4.mb-2
              v-spacer
              v-btn(
                @click="doDeleteList(list.list_id)"
                color="secondary"
                depressed rounded
                target="_new"
              )
                | Slett
                v-icon(right) mdi-delete
              v-btn.ml-6(
                v-if="isPaid(list)"
                @click="doDownloadList(list.list_id)"
                :loading="loading_download"
                :disabled="loading_download"
                color="secondary"
                depressed rounded
                target="_new"
              )
                | Last ned
                v-icon(right) mdi-download
              template(v-else)
                .ml-6(v-if="formatPrice(list).count > 0")
                  v-btn(
                    v-if="loading_purchase || isPaying(list)"
                    color="secondary"
                    loading disabled depressed rounded
                  )
                  img.pay-with-vipps(
                    v-else
                    @click="doPurchaseList(list.list_id)"
                    :src="require('../../assets/img/pay_with_vipps_pill_210_NO.svg')"
                  )
</template>

<script>
import { get } from 'lodash'
import { mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'ManageList',
  data: () => ({
    loading: false,
    loading_purchase: false,
    loading_download: false,
    loading_esk: false,
    esk: null,

    panel: [],
    interval: null
  }),
  computed: {
    ...mapState('List', ['lists']),
    processing_lists() {
      return this.lists.filter((list) => {
        return this.isGenerating(list) || this.isPaying(list)
      })
    }
  },
  watch: {
    lists: {
      immediate: true,
      handler(v) {
        // Pre open list item from URI hash
        const list_id = this.$route.hash.slice(1)
        const index = v.findIndex((list) => list.list_id === list_id)
        if (index >= 0) this.panel = index
      }
    },
    processing_lists: {
      immediate: true,
      handler(v) {
        if (v.length > 0) {
          if (!this.interval)
            this.interval = setInterval(() => this.listList(), 3000)
        } else {
          if (this.interval) {
            clearInterval(this.interval)
            this.interval = null
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('List', [
      'deleteList',
      'listList',
      'purchaseList',
      'downloadList'
    ]),
    isGenerating(list) {
      const metadata = get(list, 'metadata', null)
      return !metadata
    },
    isPaid(list) {
      const status = get(list, 'vipps.status', null)
      return status === 'SALE'
    },
    isPaying(list) {
      const status = get(list, 'vipps.status', null)
      return (
        status === 'INITIATE' || status === 'RESERVE' || status === 'CAPTURE'
      )
    },
    formatTitle(list) {
      const metadata = get(list, 'metadata', null)
      if (!metadata) return 'Liste ikke ferdig'

      const count = get(metadata, 'count', 0)
      const count_total = get(metadata, 'count_total', 0)

      return `<span class="font-weight-bold">${count} numre</span> (av totalt ${count_total})`
    },
    formatSubtitle(list) {
      const created_at = get(list, 'created_at', null)
      return created_at
        ? dayjs(parseInt(created_at)).format('D MMM HH:mm, YYYY')
        : 'ukjent'
    },
    formatHead(list) {
      try {
        return JSON.parse(get(list, 'head', '[]'))
      } catch (e) {
        return []
      }
    },
    formatPrice(list) {
      const count = get(list, 'metadata.count', 0)
      const PER_UNIT = 10

      let price = parseInt(count) * PER_UNIT
      price = price < 1 ? 1 : price // minimum 1kr
      let price_tax = price * 0.25
      let price_total = price + price_tax

      return {
        count: parseInt(count),
        price: parseFloat(price).toFixed(2),
        price_tax: parseFloat(price_tax).toFixed(2),
        price_total: parseFloat(price_total).toFixed(2)
      }
    },
    async doDeleteList(list_id = null) {
      if (
        confirm(
          'Er du helt sikker? Du kan ikke få tilbake listen selv om du har kjøpt den!'
        )
      )
        this.deleteList(list_id)
    },
    async doPurchaseList(list_id = null) {
      this.loading_purchase = true
      try {
        const url = await this.purchaseList(list_id)
        window.location.href = url
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_purchase = false
      }
    },
    async doDownloadList(list_id = null) {
      this.loading_download = true
      try {
        const url = await this.downloadList(list_id)
        window.location.href = url
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_download = false
      }
    },
    async doListList() {
      this.loading_esk = true
      try {
        // eslint-disable-next-line
        ;({ lek: this.esk } = await this.listList({ esk: this.esk }))
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_esk = false
      }
    }
  },
  async mounted() {
    this.loading = this.lists.length <= 0
    try {
      await this.doListList()
    } catch (e) {
      this.setSnackbar({
        type: 'error',
        msg: e.message
      })
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.manage-list
  .pay-with-vipps
    height 36px
    cursor pointer
    transition opacity 250ms ease-in-out

    &:hover
      opacity .7
</style>
