<template lang="pug">
.list-rotation
  .text-h5.font-weight-bold.primary--text Varighet av lister
  .text-subtitle-2.mt-4.mb-4
    | Når du oppretter en ny liste starter vi jobben med å sette sammen
    | numrene med en gang. Det tar ofte ikke lengre enn ett par sekunder. Hvis du ønsker
    | mange numre (flere tusener) tar det litt lengre tid.
  .text-subtitle-2.mb-4
    | Numrene som vi sammenstiller i listen blir markert som brukt for din organisasjon,
    | så hvis en kollega av deg oppretter en liste like etter vil duplikater ikke oppstå.
  .text-subtitle-2.mb-8
    | Listene vil være tilgjengelige for kjøp ved bruk av Vipps i en time. Hvis ikke et
    | kjøp har blitt gjort innen det sletter vi listen automatisk og frigjør samtidig
    | numrene slik at de kan dukke opp i andre lister. Kjøpte lister fjernes ikke.

  .text-h5.font-weight-bold.primary--text Markering av brukte numre
  .text-subtitle-2.mt-4.mb-4
    | Uansett hva vil numre som er markert brukt for din organisasjon bli rotert etter ca.
    | en uke. Dette betyr at numre kan begynne dukke opp i nye lister etter syv dager, selv
    | om de eksisterer i kjøpte lister.
  .text-subtitle-2
    | Oppdaterte ringelister er ferskvare, og våre systemer jobber kontinuerlig med å innhente
    | ny informasjon. Det er derfor viktig at numre har en mulighet å komme tilbake
    | i dataflyten for senere bruk.
  .d-flex.justify-center
    v-img.mt-12(
      :src="require('../../assets/img/undraw/undraw_not_found_re_44w9.svg')"
      max-width="300"
    )
</template>

<script>
export default {
  name: 'ListRotation'
}
</script>

<style lang="stylus" scoped></style>
